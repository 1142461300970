<template>
    <v-dialog v-model="visible" persistent max-width="800px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">Configurações NFC-e</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                :style="`height: ${$vuetify.breakpoint.height - 215}px; overflow-y: auto;`"
            >
                <v-alert
                    text
                    outlined
                    type="info"
                    color="primary"
                    class="mt-4 mr-5"
                    style="text-align: left; font-size: 14px;"
                >
                    <b>ATENÇÃO:</b> As configurações de Série e Número de nota fiscal eletrônica são de extrema importância. Os intervalos entre os números devem ser sequenciais, caso pule o intervalo é necessário inutilização da numeração para que não ocorra multas ao fisco.
                    <ul>
                        <li>
                            <b>Para novas empresas:</b> Informe a série igual a 1 e o número da próxima nota fiscal igual a 1.
                        </li>
                        <li>
                            <b>Substituição do emissor antigo:</b> Informe a mesma série que está sendo utilizado no emissor antigo e informe o número que deve ser emitido a próxima nota fiscal.
                        </li>
                        <li>
                            <b>Continuação do emissor antigo:</b> Será necessário começar uma nova sequência em outra série, deste modo informe uma série diferente entre 2 a 99 e o número da próxima nota fiscal igual a 1.
                        </li>
                    </ul>
                </v-alert>

                <fieldset class="v-fieldset pa-4">
                    <legend class="v-legend">Geral</legend>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="form.regime_tributario"
                                :items="['Simples Nacional']"
                                label="Regime Tributário"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="form.ambiente"
                                :items="ambientes"
                                label="Ambiente de Emissão de NFC-e"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                </fieldset>

                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">Código de Segurança do Contribuinte (CSC) | Produção</legend>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Código CSC (Produção)"
                                v-model="form.codigo_csc"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Identificador CSC (Produção)"
                                v-model="form.id_csc"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </fieldset>

                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">Código de Segurança do Contribuinte (CSC) | Homologação</legend>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Código CSC (Homologação)"
                                v-model="form.codigo_csc_dev"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Identificador CSC (Homologação)"
                                v-model="form.id_csc_dev"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </fieldset>

                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">Certificado Digital (A1)</legend>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <FileInput
                                v-model="form.certificado"
                                accept=".pfx, x-pkcs12, application/x-pkcs12"
                                validateType="pfx, x-pkcs12, application/x-pkcs12"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Senha"
                                v-model="form.senha_certificado"
                                type="password"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </fieldset>

                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">Controle de Numeração</legend>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Série"
                                v-model="form.serie"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Próxima Numeração (Produção)"
                                v-model="form.proxima_numeracao"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Próxima Numeração (Homologação)"
                                v-model="form.proxima_numeracao_dev"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </fieldset>

                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">Informações do Emitente</legend>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="CNPJ"
                                v-model="form.emitente_cnpj"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Razão Social"
                                v-model="form.emitente_razao_social"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="E-mail"
                                v-model="form.emitente_email"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Incrição Estadual"
                                v-model="form.emitente_inscricao_estadual"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Logradouro"
                                v-model="form.emitente_logradouro"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Número"
                                v-model="form.emitente_numero"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Bairro"
                                v-model="form.emitente_bairro"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                label="Municipio"
                                v-model="form.emitente_municipio"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                label="UF"
                                v-model="form.emitente_uf"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                label="CEP"
                                v-model="form.emitente_cep"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Complemento"
                                v-model="form.emitente_complemento"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </fieldset>

                <fieldset class="v-fieldset mt-6 pa-4">
                    <legend class="v-legend">Contabilidade</legend>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="E-mail da Contabilidade"
                                v-model="form.email_contabilidade"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </fieldset>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import FileInput from '@/components/FileInput';

export default {
    name: 'ConfiguracoesNotas',

    components: {
        FileInput,
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },

    data: () => ({
        loading: false,
        form: {
            regime_tributario: '',
            ambiente: '',
            codigo_csc: '',
            id_csc: '',
            codigo_csc_dev: '',
            id_csc_dev: '',
            certificado: '',
            senha_certificado: '',
            serie: '',
            proxima_numeracao: '',
            proxima_numeracao_dev: '',
            emitente_cnpj: '',
            emitente_razao_social: '',
            emitente_email: '',
            emitente_inscricao_estadual: '',
            emitente_logradouro: '',
            emitente_numero: '',
            emitente_bairro: '',
            emitente_municipio: '',
            emitente_uf: '',
            emitente_cep: '',
            emitente_complemento: '',
            email_contabilidade: '',
        },
        ambientes: [
            { value: 1, text: 'Produção' },
            { value: 2, text: 'Homologação' },
        ],
    }),

    mounted() {
        this.consultar();
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        salvar() {
            const data = { fiscal: this.form, email_contabilidade: this.form.email_contabilidade };
            this.loading = true;
            this.$http.put('fiscal', data).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify('Atualizado com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        consultar() {
            this.$root.$emit('loading', true);
            this.$http.get('fiscal').then(resp => {
                this.form = resp.data.data;
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.$root.$emit('loading', false)));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0;
}

.v-fieldset {
    border-color: #fff;
    border-radius: 5px;
    padding: 8px 8px 20px 8px;
}

.v-legend {
    color: #707070;
}

.v-expansion-panel-content {
    margin-top: 16px;
}
</style>
